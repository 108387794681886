window.tryRun("scrollspy", function () {
	let init = function (element) {
		// DATA API
		var targetData = document.querySelector(element.getAttribute('data-target'));
		var offsetData = element.getAttribute('data-offset');

		// invalidate
		if (!targetData) {
			return;
		}

		// event targets, constants
		var scrollOffset;
		var items = [];
		var targetItems = [];
		var offset = parseInt(offsetData) || 10;
		var scrollTarget = element.offsetHeight < element.scrollHeight ? element : window; // determine which is the real scrollTarget
		var isWindow = scrollTarget === window;

		// populate navigation (desktop)
		var navLinks = document.querySelectorAll('[data-spy="navigation"]');
		var navBarDesktop = targetData.getElementsByTagName('UL')[0];
		var navBarMobile = targetData.querySelectorAll('.dropdown-menu')[0];

		var arr = Array.from(navLinks).sort(function (node1, node2) {
			node1 = node1.getAttribute('data-spy-order');
			node2 = node2.getAttribute('data-spy-order');

			return node1 - node2;
		});

		// Handle Bygg Online
		var buildOnline = document.querySelector('[data-spy-order="9999"]');
		if (buildOnline) {
			arr.splice(arr.length - 2, 0, arr.splice(arr.length - 1, 1)[0]);
		}

		arr.forEach(el => {
			var li = document.createElement("li");
			var a = document.createElement("a");

			if (el.hasAttribute('data-spy-show')) {
				if (el.getAttribute('data-spy-show').toLowerCase() === "true") {
					if (el.hasAttribute('data-spy-modal')) {
						a.href = "#" + el.getAttribute('data-spy-modal');
						a.setAttribute("data-toggle", "modal");
					} else {
						if (el.id != '') {
							a.href = "#" + el.id;
							a.addEventListener('click', function (e) {
								e.preventDefault();

								var navRect = document.getElementById("scrollspy").getBoundingClientRect();
								var distanceToTop = el => Math.floor((el.getBoundingClientRect().top - (navRect.bottom - navRect.top)) + 1);
								var targetID = this.getAttribute("href");
								var targetAnchor = document.querySelector(targetID);

								if (!targetAnchor) return;

								var originalTop = distanceToTop(targetAnchor);

								if (window.navigator.userAgent.indexOf("MSIE ") > 0 || !!window.navigator.userAgent.match(/Trident.*rv\:11\./))
									window.scrollBy(0, originalTop);
								else
									window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });
							}, false);
						} else {
							var url = el.getAttribute('data-url');
							a.href = url;
							a.target = "_blank";
						}
					}
					a.innerHTML = el.dataset.title;

					// Google Analytics
					var pc = document.querySelector('.product__content');
					if (pc) {
						if (pc.hasAttribute('data-ga-type')) {
							var productTitle = document.querySelector('.product__header h1').innerText;
							var productType = pc.getAttribute('data-ga-type');

							a.setAttribute('data-ga', 'track');

							var category = (el.hasAttribute('data-category')) ? el.getAttribute('data-category') : 'Klick';
							a.setAttribute('data-category', category);

							var action = (el.hasAttribute('data-action')) ? el.getAttribute('data-action') : 'Flik klick';
							a.setAttribute('data-action', action);

							var label = (el.hasAttribute('data-label')) ? el.getAttribute('data-label') : productType + ' - ' + el.dataset.title + ': ' + productTitle;
							a.setAttribute('data-label', label);
						}
					}

					var aMobile = a.cloneNode(true);
					aMobile.addEventListener('click', function (e) {
						e.preventDefault();

						var distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
						var targetID = this.getAttribute("href");
						if (targetID.startsWith('#')) {
							var targetAnchor = document.querySelector(targetID);

							if (!targetAnchor) return;

							var originalTop = distanceToTop(targetAnchor);

							window.scrollBy({ top: originalTop, left: 0, behavior: "smooth" });

							var checkIfDone = setInterval(function () {
								var atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
								if (distanceToTop(targetAnchor) === 0 || atBottom) {
									targetAnchor.tabIndex = "-1";
									targetAnchor.focus();
									clearInterval(checkIfDone);
								}
							}, 100);
						} else {
							var win = window.open(targetID, '_blank');
							win.focus();
						}
					}, false);
					aMobile.classList.add('dropdown-item');

					li.appendChild(a);
					navBarDesktop.appendChild(li);
					navBarMobile.appendChild(aMobile);
				}
			}
		});

		// Add last for mobile
		if (navBarMobile.lastChild != null) {
			var lastNode = navBarMobile.lastChild.cloneNode(true);
			if (!lastNode.classList) {
				return;
			}

			lastNode.classList.add('button-last');
			lastNode.classList.add('hide-for-large');
			lastNode.classList.remove('dropdown-item');
			navBarMobile.parentNode.parentNode.appendChild(lastNode);
			navBarMobile.removeChild(navBarMobile.lastChild);
		}

		// populate items and targets
		var links = targetData && targetData.getElementsByTagName('A');
		for (var i = 0; i < links.length; i++) {
			var href = links[i].getAttribute('href');
			var targetItem = href && href.charAt(0) === '#' && href.slice(-1) !== '#' && document.querySelector(href);
			if (!!targetItem) {
				items.push(links[i]);
				targetItems.push(targetItem);
			}
		}

		let updateItem = function (index) {
			var item = items[index];
			var targetItem = targetItems[index]; // the menu item targets this element
			var dropdown = item.parentNode.parentNode;
			var dropdownLink = dropdown.classList.contains('dropdown') && dropdown.getElementsByTagName('A')[0];
			var targetRect = isWindow && targetItem.getBoundingClientRect();
			var isActive = item.classList.contains('active') || false;
			var topEdge = (isWindow ? targetRect.top + scrollOffset : targetItem.offsetTop) - offset;
			var bottomEdge = isWindow ? targetRect.bottom + scrollOffset - offset : targetItems[index + 1] ? targetItems[index + 1].offsetTop - offset : element.scrollHeight;
			var inside = scrollOffset >= topEdge && bottomEdge > scrollOffset;

			if (!isActive && inside) {
				if (!item.classList.contains('active')) {
					item.classList.add('active');
					item.parentNode.classList.add('active');
					if (dropdownLink && !dropdownLink.classList.contains('active')) {
						dropdownLink.classList.add('active');
					}
				}
			} else if (!inside) {
				if (item.classList.contains('active')) {
					item.classList.remove('active');
					item.parentNode.classList.remove('active');
					if (dropdownLink && dropdownLink.classList.contains('active') && !item.parentNode.getElementsByClassName('active').length) {
						dropdownLink.classList.remove('active');
					}
				}
			} else if (!inside && !isActive || isActive && inside) {
				return;
			}
		};

		let updateItems = function () {
			scrollOffset = isWindow ? window.pageYOffset || document.documentElement.scrollTop : element.scrollTop;
			for (var index = 0; index < items.length; index++) {
				updateItem(index)
			}
		};

		let refresh = function () {
			updateItems();
		}

		if (!('Scrollspy' in element)) { // prevent adding event handlers twice
			scrollTarget.addEventListener('scroll', refresh, false);
			window.addEventListener('resize', refresh, false);
		}
		refresh();

		element.Scrollspy = 'Scrollspy';
	};

	var elements = document.querySelectorAll('[data-spy="scroll"]');
	for (var i = 0; i < elements.length; i++) {
		init(elements[i]);
	}
});